<template>
  <v-row>
    <v-col
      cols="12"
      lg="7"
      xl="6"
      class="blue darken-2 d-none d-md-flex align-center justify-center"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="12" xl="8">
              <div>
                <h1 class="display-3 white--text font-weight-medium">EMR</h1>
                <h2 class="display-1 mt-2 white--text font-weight-medium">
                  {{ $t("login.messages_Intro.title") }}
                </h2>
                <h4
                  class="subtitle-3 mt-5 white--text op-5 font-weight-regular"
                >
                  {{ $t("login.messages_Intro.text_1") }}
                  <a class="white--text" href="https://github.com/nichealpham/his-composer"
                    ><u>{{ $t("login.messages_Intro.text_slogan") }}</u></a
                  >
                  {{ $t("login.messages_Intro.text_2") }}
                </h4>
                <h6
                  class="subtitle-2 mt-1 white--text op-5 font-weight-regular"
                >
                  Copyright @2021 HIS Inc. All Rights Reserved.
                </h6>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <img src="@/assets/images/logo-icon-dark.png" />
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
                {{ $t("login.title") }}
              </h2>
              <h6 class="subtitle-1">
                {{ $t("login.title_intro") }}
                <a href="https://github.com/nichealpham/his-composer" class
                  >{{ $t("login.title_intro1") }} a2ds</a
                >
              </h6>

              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                action="/dashboards/analytical"
              >
                <v-text-field
                  v-model="email"
                  :label="$t('login.input_text.user')"
                  class="mt-4"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :counter="10"
                  :rules="passwordRules"
                  :label="$t('login.input_text.password')"
                  required
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                ></v-text-field>

                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0"></div>
                <v-alert
                  v-model="showError"
                  dismissible
                  dense
                  outlined
                  type="error"
                >
                  {{ errorMessage }}
                </v-alert>
                <v-btn
                  :disabled="!valid"
                  block
                  class="mr-4 blue darken-1 white--text"
                  submit
                  @click="submit"
                  :loading="loading"
                  >{{ $t("login.buttons.login") }}</v-btn
                >

                <!-- <v-select
                  type="text"
                  solo
                  class="mt-4"
                  style="width: 200px; float: right"
                  :items="optionLangs"
                  v-model="langDefault"
                  @change="callSetLangActions"
                  :label="$t('login.buttons.language')"
                ></v-select> -->
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import AuthenticationService from "@/services/authentication";
import i18n from "@/plugins/i18n";

export default {
  name: "FullLogin",
  data: () => ({
    valid: true,
    password: "",
    show1: false,
    passwordRules: [
      (v) => !!v || i18n.t("common.passRe"),
      (v) => (v && v.length <= 10) || i18n.t("common.pass10Re"),
    ],
    email: "",
    emailRules: [
      (v) => !!v || i18n.t("common.usernameRe"),
      (v) => /.+@.+\..+/.test(v) || i18n.t("common.usernameInRe"),
    ],
    errorMessage: "",
    showError: false,
    loading: false,
    optionLangs: [
      {
        text: "Vietnamese",
        value: "vn",
      },
      {
        text: "English",
        value: "en",
      },
    ],
    langDefault: "",
    // checkbox: false,
  }),
  computed: {},
  created() {
    if (this.$route.query.username) {
      this.email = this.$route.query.username;
    }
    if (this.$route.query.password) {
      this.password = this.$route.query.password;
    }
  },
  mounted() {
    this.getOptionLanguage();
  },
  methods: {
    getOptionLanguage() {
      this.langDefault = localStorage.getItem("LOCATE_LANGUAGE") || "en";
    },
    callSetLangActions(e) {
      // this.$store.dispatch("SetLang/changeLocale", e);
      localStorage.setItem("LOCATE_LANGUAGE", e);
      i18n.locale = e; // important!
    },
    async submit() {
      this.showError = false;
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.loading = true;
        var result = await AuthenticationService.login(
          this.email,
          this.password
        );
        this.loading = false;
        if (result.error) {
          this.errorMessage = "Invalid Login credentials!";
          this.showError = true;
          return;
        }
        this.$store.commit("SET_ACCESS_TOKEN", result.tokenID);
        this.$store.commit("SET_USER_DATA", result.authData);
        this.$router.push({ path: "/index" });
        console.log(this.$store);
      }
    },
  },
};
</script>
